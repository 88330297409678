export enum UserRoleEnum {
    DASHBOARD = 'dashboard',
    USER = 'user',
    UPLOAD = 'upload',
    QP_GENERATION = 'qp_generation',
    DOWNLOAD = 'download',
    // SUBJECT = "subject",
    QA = "qa",
    AI_QA = "ai_qa",
    REQUESTS = 'requests', 
    VIEW = 'view',
    // API_KEY = 'apiKey',
    // AUTO_UPLOAD = 'auto-upload',
    TRANSLATOR = 'translator',
    EXAM_CATEGORY = 'exam_category',
    SUBJECT = 'subject'
}